<template>
    <div class="stats-main" v-if="stats">
        <div class="stats-item" v-if="stats.centers">
            <div class="stats-number">{{ stats.centers}}</div>
            <div class="stats-name">{{ $t('stats.center')}}</div>
        </div>
        <div class="stats-item" v-if="stats.doctors || stats.doctors === 0">
            <div class="stats-number">{{ stats.doctors}}</div>
            <div class="stats-name">{{ $t('stats.doctors')}}</div>
        </div>
        <div class="stats-item"  v-if="stats.patients || stats.patients === 0">
            <div class="stats-number">{{ stats.patients}}</div>
            <div class="stats-name">{{ $t('stats.patients')}}</div>
        </div>
        <div class="stats-item">
            <div class="stats-number">{{ stats.process}}</div>
            <div class="stats-name" >
                {{$t('stats.processing')}}
                <div class="stats-name-light">{{$t('stats.processingSub')}}</div>
            </div>
        </div>
        <div class="stat-pie">
            <div class="stats-item" >
                <div class="pie-segment" :style="{'--offset': offset, '--value': value, '--over50': over, 'overflow' : overShow}"></div>
                <div class="stats-number">{{ total }}</div>
                <div class="stats-name">
                    {{$t('stats.modules')}}
                    <div class="stats-name-light">{{$t('stats.modulesSub')}}</div>
                </div>
            </div>
            <div class="pie-infos">
                <div class="pie-infos-item">
                    <div class="pie-icon pie-patient"></div>
                    <div class="pie-name">{{ stats.byPatient + ' ' + $t('stats.modulesAnswersPatient') }}</div>
                </div>
                <div class="pie-infos-item">
                    <div class="pie-icon"></div>
                    <div class="pie-name">{{ stats.byCoordinator + ' ' + $t('stats.modulesAnswersCoordinator') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'StatsMain',
    props : ['stats'],
    data() {
        return {
            offset : 0,
            value : 0,
            over : 0 ,
            total : 0,
            overShow : null
        }
    },
    watch: {
        stats() {
            this.total = this.stats.byPatient + this.stats.byCoordinator;
            this.value = (this.stats.byCoordinator / this.total) * 100;
            this.value > 50 ? this.over = 1 : this.over = 0; 
            this.value > 50 ? this.overShow = 'visible' : this.overShow = 'hidden'; 
        }

    }
}
</script>

<style lang="scss" scoped>
    .stats-main {
        display:flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width:100%;
        margin: 20px auto;
    }

    .stats-item {
        width:200px;
        min-width: 200px;
        height:200px;
        background: $mainColor;
        border-radius:50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin:20px;
        color: #fff;
        position:relative;
        overflow: hidden;
    }

    .stats-number {
        font-size:45px;
        font-display: $fontMedium;
        text-align: center;
        margin-top:55px;
        position: relative;
        z-index: 10;
    }

    .stats-name {
        font-size:25px;
        font-display: $fontBlack;
        text-align: center;
        position: relative;
        z-index: 10;
    }

    .stats-name-light {
        font-size:15px;
    }

    .pie-segment {
        --degrees: calc((var(--offset, 0 ) / 100) * 360);
        height:100%;
        position: absolute;
        transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
        transform-origin: 50% 100%;
        width: 100%;
    }

    .pie-segment:before, .pie-segment:after {
        background: $error;
        content:'';
        height:100%;
        position: absolute;
        width:100%;
    }

    .pie-segment:before {
        --degrees: calc((var(--value, 45 ) / 100) * 360);
        transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
        transform-origin: 50% 0;
    }

    .pie-segment:after {
        opacity: var(--over50, 0);
    }

    .pie-infos-item {
        display: flex;
        align-items: center;
        margin:5px 0;
    }

    .pie-icon  {
        height:18px;
        width: 18px;
        min-width: 18px;
        border-radius: 50%;
        margin-right: 10px;
        background: $error;
    }

    .pie-icon.pie-patient {
        background: $mainColor;
    }

    .pie-name {
        font-size:15px;
        color:$textColor;
        font-family: $font;
    }
</style>