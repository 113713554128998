<template>
    <div class="pathology-item">
        <div class="pathology-name">{{ JSON.parse(pathology.name)[$store.state.LANG] }}</div>
        <div class="pathology-stat">
            <div class="pathology-bar">
                <div class="pathology-advance" :style="{ right : advance + '%'}"></div>
            </div>
            <div class="pahtology-number">{{ pathology.value }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'StatPathology',
    props : ['max', 'pathology'],
    data() {
        return {
            advance : 0
        }
    },
    watch : {
        max() {
            this.advance =  100 - (( this.pathology.value / this.max ) * 100);
        }
    },
    mounted() {
        this.advance =  100 - (( this.pathology.value / this.max ) * 100);
    }
}
</script>

<style lang="scss" scoped>
    .pathology-item  {
        display: flex;
        align-items: center;
        margin:10px auto;
        width:800%;
        max-width: 90%;
    }

    .pathology-stat {
        display: flex;
        align-items: center;
    }

    .pathology-name {
        width: 300px;
        font-size:18px;
        color:$textColor;
        font-family: $font;
    }

    .pathology-bar {
        width:300px;
        height:25px;
        border-radius:5px;
        background: $inputBackground;
        position:relative;
        margin:0 10px;
    }

    .pathology-advance {
        position: absolute;
        left:0;
        top:0;
        bottom:0;
        border-radius:5px;
        background: $mainColor;
        transition:0.5s;
    }

    .pathology-number {
        font-size:18px;
        color:$textColor;
        font-family: $font;
    }
</style>